// eslint-disable-next-line import/no-extraneous-dependencies
import RoomState from "common/types/RoomState";

export const hasDifferentPicks = (oldState?: RoomState, newState?: RoomState): boolean => {
    if(oldState == null && newState == null){
        return false;
    } if(oldState == null || newState == null){
        return true;
    }
    const oldNumChamps = oldState.blueBans.length + oldState.redBans.length + oldState.bluePicks.length + oldState.redPicks.length;
    const newNumChamps = newState.blueBans.length + newState.redBans.length + newState.bluePicks.length + newState.redPicks.length;

    return oldNumChamps !== newNumChamps;
};

export default {};