/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createRef, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AdminStatsMessage,
  Message,
  RoomCreatedMessage,
} from "common/types/Messages";
import { Link, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";
// eslint-disable-next-line import/no-extraneous-dependencies
import RoomState from "common/types/RoomState";
import { Base64 } from "js-base64";
import getWebsocketUrl from "../loaders/getWebSocketUrl";
import copyToClipboard from "../utils/copyToClipboard";
import { defaults, RoomOptions } from "./CreateRoom";
import SocialIconsBar from "./SocialIconsBar";

const RoomInfo = () => {
  const {
    roomId,
    bluePassword,
    redPassword,
    adminPassword,
  } = useParams();
  const [showAdvOpt, setShowAdvOpt] = useState(false);

  const blueOutput = createRef<HTMLInputElement>();
  const redOutput = createRef<HTMLInputElement>();
  const spectatorOutput = createRef<HTMLInputElement>();
  const allOutput = createRef<HTMLInputElement>();

  const [state, setState] = useState<RoomState>();
  const [stats, setStats] = useState<AdminStatsMessage>();

  const getBaseUrl = () =>
    `${window.location.protocol}//${window.location.host}`;
  const getBluePick = (local = false) =>
    `${local ? "" : getBaseUrl()}/${roomId}/${bluePassword}`;
  const getRedPick = (local = false) =>
    `${local ? "" : getBaseUrl()}/${roomId}/${redPassword}`;
  const getSpectatorLink = (local = false) =>
    `${local ? "" : getBaseUrl()}/${roomId}`;
  const getAllLink = () =>
    `Blue pick: ${getBluePick()}\t\t\t\nRed pick: ${getRedPick()}\t\t\t\nSpectator: ${getSpectatorLink()}`;

  const getRestUsers = () => {
    if (stats) {
      return (
        stats.numTotalPickers -
        (stats.numBluePickers + stats.numRedPickers + stats.numAdminPickers)
      );
    }
    return null;
  };

  const onOpen = () => {
    // eslint-disable-next-line no-use-before-define
    sendJsonMessage({
      type: "joinroom",
      roomId: roomId,
      password: adminPassword,
    });
  };

  const onMessage = (message: MessageEvent<string>) => {
    const parsedMessage: Message = JSON.parse(message.data);
    if (parsedMessage.type === "statechange") {
      setState(parsedMessage.newState);
    } else if (parsedMessage.type === "adminstats") {
      setStats(parsedMessage);
    } else if (parsedMessage.type === "error") {
      // setErrorState(parsedMessage.reason);
    }
  };

  const { sendJsonMessage } = useWebSocket(getWebsocketUrl, {
    onOpen,
    onMessage,
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
  });

  const cyclePicker = (side: string) => () => {
    sendJsonMessage({ type: "cyclepicker", side });
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) =>
    event.target.select();

  const copyAllLink = (
    // e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(getAllLink());
    } else {
      if (allOutput.current) {
        allOutput.current.select();
      }
      document.execCommand("copy");
      // e.target.focus();
    }
  };

  const getSameConfigurationLink = () => {
    const opts: Partial<RoomOptions> = {
      t: state && state.disabledTurns,
      c: state && state.disabledChamps,
      p: state && state.timePerPick,
      b: state && state.timePerBan,
    };

    if (opts.t && opts.t.length === 0) {
      delete opts.t;
    }

    if (opts.c && opts.c.length === 0) {
      delete opts.c;
    }

    if (opts.p === defaults.timePerPick) {
      delete opts.p;
    }

    if (opts.b === defaults.timePerBan) {
      delete opts.b;
    }

    const encoded = Base64.encodeURI(JSON.stringify(opts));

    return Object.keys(opts).length === 0 ? "/" : `/?opts=${encoded}`;
  };

  return (
    <>
      <div className="createContainer">
        <div>
          <div className="outputContainer">
            <Link
              className="outputButton outputButtonCenter"
              to={getSameConfigurationLink()}
              target="_blank"
            >
              New draft
            </Link>
          </div>
          <label>Blue</label>
          <div className="outputContainer">
            <input
              ref={blueOutput}
              className="inputBlue"
              type="text"
              value={getBluePick()}
              readOnly
              onFocus={handleFocus}
            />
            <Link className="outputButton" to={getBluePick(true)} target="_blank">
              Open
            </Link>
            <div className="outputButton" onClick={copyToClipboard(blueOutput)}>
              Copy
            </div>
          </div>
          <label>Red</label>
          <div className="outputContainer">
            <input
              ref={redOutput}
              className="inputRed"
              type="text"
              value={getRedPick()}
              readOnly
              onFocus={handleFocus}
            />
            <Link className="outputButton" to={getRedPick(true)} target="_blank">
              Open
            </Link>
            <div className="outputButton" onClick={copyToClipboard(redOutput)}>
              Copy
            </div>
          </div>

          <label>Spectator</label>
          <div className="outputContainer">
            <input
              ref={spectatorOutput}
              className=""
              type="text"
              value={getSpectatorLink()}
              readOnly
              onFocus={handleFocus}
            />
            <Link
              className="outputButton"
              to={getSpectatorLink(true)}
              target="_blank"
            >
              Open
            </Link>
            <div
              className="outputButton"
              onClick={copyToClipboard(spectatorOutput)}
            >
              Copy
            </div>
          </div>

          {navigator.clipboard === undefined ? <label>All</label> : <label />}
          <div className="outputContainer">
            {navigator.clipboard === undefined ? (
              <input
                ref={allOutput}
                type="text"
                value={getAllLink()}
                readOnly
                onFocus={handleFocus}
              />
            ) : (
              <></>
            )}
            <div
              className={`outputButton ${navigator.clipboard !== undefined ? "outputButtonCenter" : ""
                }`}
              onClick={copyAllLink}
            >
              Copy All
            </div>
          </div>
          <div className="outputContainer">
            <div
              className="advancedOptionsButton"
              onClick={() => setShowAdvOpt((s) => !s)}
            >
              Adv options
            </div>
          </div>
          {showAdvOpt && (
            <div className="outputContainer">
              <div className="textContainer">
                Blue Pickers: {stats && stats.numBluePickers}
              </div>
              <div
                className="outputButton inputBlue"
                onClick={cyclePicker("blue")}
              >
                Cycle Blue
              </div>
              <div className="textContainer">
                Red Pickers: {stats && stats.numRedPickers}
              </div>
              <div className="outputButton inputRed" onClick={cyclePicker("red")}>
                Cycle Red
              </div>
              <div className="textContainer">
                Admins: {stats && stats.numAdminPickers}
              </div>
              <div className="textContainer">Rest: {getRestUsers()}</div>
            </div>
          )}
        </div>
      </div>
      <SocialIconsBar />
    </>
  );
};

export default RoomInfo;
