import { Dispatch, useState } from "react";

interface AppSettings {
  sideView: "small" | "large";
  bansPosition: "tournamentRealm" | "normal";
  soundsEnabled: boolean;
  version: undefined | "1" | "2";
}

const DefaultAppSettings: AppSettings = {
  sideView: "large",
  bansPosition: "tournamentRealm",
  soundsEnabled: true,
  version: "2",
};

const cycleSideView = (
  setter: Dispatch<React.SetStateAction<AppSettings>>
) => () => {
  setter((current) => ({
    ...current,
    sideView: current.sideView === "small" ? "large" : "small",
  }));
};

const cycleBansView = (
  setter: Dispatch<React.SetStateAction<AppSettings>>
) => () => {
  setter((current) => ({
    ...current,
    bansPosition:
      current.bansPosition === "normal" ? "tournamentRealm" : "normal",
  }));
};

const toggleSoundsEnabled = (
  setter: Dispatch<React.SetStateAction<AppSettings>>
) => () => {
  setter((current) => ({
    ...current,
    soundsEnabled:!current.soundsEnabled}));
};

export type AppSettingsSetters = {
  cycleBansView: ()=>void,
  cycleSideView: ()=>void,
  toggleSoundsEnabled: ()=>void,
}

type UseAppSettingsReturnType = [AppSettings, AppSettingsSetters]

export const useAppSettings = (): UseAppSettingsReturnType => {
  const [appSettings, setAppSettings] = useState((): AppSettings => {
    const s = localStorage.getItem("draftlol-app-settings");
    if (s !== null) {
      
      try {
        const parsed = JSON.parse(s);
        if(parsed.version !== DefaultAppSettings.version){
          return DefaultAppSettings;
        }
        return {...DefaultAppSettings, ...JSON.parse(s)};
      } catch (e) {
        // failed to parse, load default
      }
    }
    return DefaultAppSettings;
  });
  return [appSettings, {
    cycleBansView: cycleBansView(setAppSettings),
    cycleSideView: cycleSideView(setAppSettings),
    toggleSoundsEnabled: toggleSoundsEnabled(setAppSettings),
  }];
};

export default AppSettings;
