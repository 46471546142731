import React from "react";
import KofiButton from "kofi-button";
import { SocialIcon } from "react-social-icons";

interface SocialIconsBarProps {
    singleLine?: boolean;
  }

const SocialIconsBar = (props: SocialIconsBarProps) => {
    const {singleLine} = props;

    return <div className={`socialIconsBar ${singleLine? "singleLine": ""}`}>
    <KofiButton color="#0a9396" title="Donate" kofiID="dawe_gg" />
    <SocialIcon url="mailto:draftlol@dawe.gg" />
    <SocialIcon url="https://twitter.com/Daniferrito" />
    <SocialIcon url="https://www.reddit.com/user/daniferrito" />
  </div>;
};

  export default SocialIconsBar;