import { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Champion from "common/types/Champion";

const loadChampions = async (version: string): Promise<{ [key: string]: Champion }> => {
  const res = await (await fetch(
    `https://ddragon.leagueoflegends.com/cdn/${version}/data/en_US/champion.json`
  )).json();
  return res.data;
};

const useLolChampions = (version: string) => {
  const [champions, setChampions] = useState<{ [key: string]: Champion } | undefined>(undefined);
  useEffect(() => {
    loadChampions(version).then(setChampions);
  }, [version]);
  return champions;
};

export default useLolChampions;