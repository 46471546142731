/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, createRef, FormEvent, useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Message, RoomCreatedMessage } from "common/types/Messages";
import useWebSocket from "react-use-websocket";
import { Base64 } from 'js-base64';
import { Navigate, useSearchParams } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import Champion from "common/types/Champion";
import getWebsocketUrl from "../loaders/getWebSocketUrl";
import useLolChampions from "../hooks/useLolChampions";
import {
  optionsToDisableBlue,
  optionsToDisableRed,
} from "../data/DisabledBanPicks";
import copyToClipboard from "../utils/copyToClipboard";
import getBaseUrl from "../utils/getBaseUrl";
import ChampsWithFilters from "./ChampsWithFilters";
import SocialIconsBar from "./SocialIconsBar";

interface CreateRoomProps {
  version: string;
}

interface TurnOption {
  turn: number;
  display: string;
  index: number;
  array: string;
}

interface FilledTurnOption extends TurnOption {
  isChecked: boolean;
}

interface RoomConfiguration {
  disabledBlueOptions: FilledTurnOption[];
  disabledRedOptions: FilledTurnOption[];
  disabledChamps: { champion: Champion; isChecked: boolean }[] | undefined;
  timePerBan: number;
  timePerPick: number;
}

export interface RoomOptions {
  t: number[];
  c: string[] | number[];
  b: number;
  p: number;
}

export const defaults = {
  timePerBan: 30,
  timePerPick: 30,
};

const CreateRoom = (props: CreateRoomProps) => {
  const { version } = props;
  const [params] = useSearchParams();

  const [roomConfiguration, setRoomConfiguration] = useState<RoomConfiguration>(
    () => {
      const { opts } = Object.fromEntries(params);
      let deserialized: Partial<RoomOptions> = {};
      try {
        let decoded = Base64.decode(opts);
        if (!decoded.startsWith("{")) {
          decoded = `{${decoded}}`;
        }
        deserialized = JSON.parse(decoded);
      } catch (e) {
        // DO NOTHING
      }

      const disabledBlueOptions = optionsToDisableBlue.map((it) => ({
        ...it,
        isChecked:
          (deserialized.t && deserialized.t.includes(it.turn)) || false,
      }));
      const disabledRedOptions = optionsToDisableRed.map((it) => ({
        ...it,
        isChecked:
          (deserialized.t && deserialized.t.includes(it.turn)) || false,
      }));

      return {
        disabledBlueOptions,
        disabledRedOptions,
        disabledChamps: undefined,
        timePerBan: deserialized.b || defaults.timePerBan,
        timePerPick: deserialized.p || defaults.timePerPick,
      };
    }
  );

  const blueName = createRef<HTMLInputElement>();
  const redName = createRef<HTMLInputElement>();
  const configOutput = createRef<HTMLInputElement>();
  const [roomInfo, setRoomInfo] = useState<RoomCreatedMessage>();
  const [showAdvOpt, setShowAdvOpt] = useState(false);
  // const [champNameFilter, setChampNameFilter] = useState("");
  const champs = useLolChampions(version);
  useEffect(() => {
    if (champs != null) {
      const { opts } = Object.fromEntries(params);
      let deserialized: Partial<RoomOptions> = {};
      try {
        let decoded = Base64.decode(opts);
        if (!decoded.startsWith("{")) {
          decoded = `{${decoded}}`;
        }
        deserialized = JSON.parse(decoded);
      } catch (e) {
        // DO NOTHING
      }
      setRoomConfiguration((prevState) => ({
        ...prevState,
        disabledChamps: Object.values(champs).map((champion) => ({
          champion,
          isChecked:
            (deserialized.c && deserialized.c.some((c: string | number) => c.toString() === champion.key || c.toString() === champion.id)) || false,
        })),
      }));
    }
  }, [champs]);

  /* const [timePerBan, setTimePerBan] = useState(30);
  const [timePerPick, setTimePerPick] = useState(30); */

  const onMessage = (message: MessageEvent<string>) => {
    const parsedMessage: Message = JSON.parse(message.data);
    if (parsedMessage.type === "roomcreated") {
      setRoomInfo(parsedMessage);
    }
  };

  const { sendJsonMessage } = useWebSocket(getWebsocketUrl, {
    onMessage,
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
  });

  // const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChampNameFilter(event.target.value);
  // };

  const createRoom = (event: FormEvent) => {
    sendJsonMessage({
      type: "createroom",
      blueName: (blueName.current && blueName.current.value) || "Blue",
      redName: (redName.current && redName.current.value) || "Red",
      disabledTurns: roomConfiguration.disabledBlueOptions
        .concat(roomConfiguration.disabledRedOptions)
        .filter((opt) => opt.isChecked)
        .map((opt) => opt.turn),
      disabledChamps: roomConfiguration.disabledChamps
        ? roomConfiguration.disabledChamps
          .filter((opt) => opt.isChecked)
          .map((opt) => opt.champion.id)
        : [],
      timePerPick: roomConfiguration.timePerPick,
      timePerBan: roomConfiguration.timePerBan,
    });
    event.preventDefault();
  };

  const handleCheckCheckboxBlue = (i: number) => (
    // event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    setRoomConfiguration((prevState) => ({
      ...prevState,
      disabledBlueOptions: prevState.disabledBlueOptions.map((it, index) =>
        index === i ? { ...it, isChecked: !it.isChecked } : it
      ),
    }));
  };

  const handleCheckCheckboxRed = (i: number) => (
    // event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    setRoomConfiguration((prevState) => ({
      ...prevState,
      disabledRedOptions: prevState.disabledRedOptions.map((it, index) =>
        index === i ? { ...it, isChecked: !it.isChecked } : it
      ),
    }));
  };

  const handleTimePerChange = (setter: (val: number) => void) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      let newValue = Number(event.target.value);
      if (Number.isNaN(newValue) || newValue === Infinity) {
        return;
      }
      if (newValue < 0) {
        newValue = 0;
      }
      if (newValue > 3600) {
        newValue = 3600;
      }
      setter(newValue);
    } catch (e) {
      // DO NOTHING
    }
  };

  const setTimePerBan = (val: number) => {
    setRoomConfiguration((prevState) => ({
      ...prevState,
      timePerBan: val,
    }));
  };

  const setTimePerPick = (val: number) => {
    setRoomConfiguration((prevState) => ({
      ...prevState,
      timePerPick: val,
    }));
  };

  const handleChampToogle = (champion: Champion) => {
    setRoomConfiguration((prevState) => ({
      ...prevState,
      disabledChamps:
        prevState.disabledChamps &&
        prevState.disabledChamps.map((it) =>
          it.champion.id === champion.id
            ? { ...it, isChecked: !it.isChecked }
            : it
        ),
    }));
  };

  const isChampDisabled = (championId: string) => roomConfiguration.disabledChamps
    .find(c => c.champion.id === championId)
    .isChecked;

  const getConfigOutput = () => {
    const opts: Partial<RoomOptions> = {
      t: roomConfiguration.disabledBlueOptions
        .concat(roomConfiguration.disabledRedOptions)
        .filter((opt) => opt.isChecked)
        .map((opt) => opt.turn),
      c: roomConfiguration.disabledChamps
        ? roomConfiguration.disabledChamps
          .filter((opt) => opt.isChecked)
          .map((opt) => +opt.champion.key)
        : [],
      p: roomConfiguration.timePerPick,
      b: roomConfiguration.timePerBan,
    };

    if (opts.t && opts.t.length === 0) {
      delete opts.t;
    }

    if (opts.c && opts.c.length === 0) {
      delete opts.c;
    }

    if (opts.p === defaults.timePerPick) {
      delete opts.p;
    }

    if (opts.b === defaults.timePerBan) {
      delete opts.b;
    }

    let stringified = JSON.stringify(opts);
    stringified = stringified.substring(1, stringified.length - 1);
    const encoded = Base64.encodeURI(stringified);


    return Object.keys(opts).length === 0
      ? getBaseUrl()
      : `${getBaseUrl()}?opts=${encoded}`;
  };

  if (roomInfo) {
    return (
      <Navigate
        to={`/${roomInfo.roomId}/${roomInfo.adminPassword}/${roomInfo.bluePassword}/${roomInfo.redPassword}`}
      />
    );
  }
  return (
    <>
      <div className="createContainer">
        <div className="sendLine" onSubmit={createRoom}>
          <label>Blue team name</label>
          <input className="inputLine inputBlue" type="text" placeholder="Blue" ref={blueName} />
          <label>Red team name</label>
          <input className="inputLine inputRed" type="text" placeholder="Red" ref={redName} />
          <div className="sendButton" onClick={createRoom}>
            Create
            </div>
        </div>
        <div
          className="advancedOptionsButton"
          onClick={() => setShowAdvOpt((s) => !s)}
        >
          Adv options
          </div>
        {showAdvOpt ? (
          <div className="advancedOptionsSuperContainer">
            <div>
              <h3>Permanent link to this configuration</h3>
              <div className="outputContainer">
                <input
                  ref={configOutput}
                  className=""
                  type="text"
                  value={getConfigOutput()}
                  readOnly
                />
                <div
                  className="outputButton"
                  onClick={copyToClipboard(configOutput)}
                >
                  Copy
                  </div>
              </div>
            </div>
            <div className="advancedOptionContainer">
              <div className="inputDisabledOptionsContainer">
                <div>
                  <label>Time per ban: </label>
                  <input
                    value={roomConfiguration.timePerBan}
                    onChange={handleTimePerChange(setTimePerBan)}
                  />
                </div>
                <div>
                  <label>Time per pick: </label>
                  <input
                    value={roomConfiguration.timePerPick}
                    onChange={handleTimePerChange(setTimePerPick)}
                  />
                </div>
              </div>
              <div className="inputDisabledOptionsContainer">
                Blue
                  {roomConfiguration.disabledBlueOptions.map((opt, i) => (
                <div
                  className="inputDisabledOptionsElement"
                  key={opt.turn}
                  onClick={handleCheckCheckboxBlue(i)}
                >
                  <input
                    readOnly
                    type="checkbox"
                    checked={opt.isChecked}
                  />
                  {opt.display}
                </div>
              ))}
              </div>
              <div className="inputDisabledOptionsContainer">
                Red
                  {roomConfiguration.disabledRedOptions.map((opt, i) => (
                <div
                  className="inputDisabledOptionsElement"
                  key={opt.turn}
                  onClick={handleCheckCheckboxRed(i)}
                >
                  <input
                    readOnly
                    type="checkbox"
                    checked={opt.isChecked}
                  />
                  {opt.display}
                </div>
              ))}
              </div>
            </div>
            <div className="disabledChamps">
              <h3 className="disabledChampsLabel"> Disable champions </h3>
              <div> 
                <ChampsWithFilters
                  version={version}
                  isChampDisabled={isChampDisabled}
                  onChampSelected={handleChampToogle}
                  allowSelectDisabled
                />
              </div>
            </div>
            {/* <div className="roomFiltersSearch" style={{height: '34px'}}>
                  <input onChange={onFilterChange} />
                  <img src="/search-icon.svg" alt="" />
                </div>
              <div className="advancedOptionContainer">
                {roomConfiguration.disabledChamps ? (
                  <div className="roomChampionsList roomChampionsListReduced">
                    {roomConfiguration.disabledChamps
                      .filter((champion) =>
                        new RegExp(
                          champNameFilter.split("").join(".*"),
                          "i"
                        ).test(champion.champion.name)
                      )
                      .map(({ champion, isChecked }) => (
                        <img
                          key={champion.id}
                          alt={champion.name}
                          // @ts-ignore
                          disabled={isChecked}
                          onClick={handleChampToogle(champion)}
                          className={`roomImgListItem`}
                          src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.image.full} `}
                        />
                      ))}
                  </div>
                ) : (
                  <></>
                )}
              </div> */}
          </div>
        ) : (
          <></>
        )}
      </div>
      <SocialIconsBar />
    </>
  );

};

export default CreateRoom;
