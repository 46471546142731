/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Message } from "common/types/Messages";
import { Link, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";
// eslint-disable-next-line import/no-extraneous-dependencies
import Champion from "common/types/Champion";
// eslint-disable-next-line import/no-extraneous-dependencies
import RoomState from "common/types/RoomState";
import useSound from "use-sound";
import useLolChampions from "../hooks/useLolChampions";
import useLolRoles from "../hooks/useLolRoles";
import getWebsocketUrl from "../loaders/getWebSocketUrl";
import { optionsToDisableBoth } from "../data/DisabledBanPicks";
import fixChampTileNames from "../fixes/fixChampTileNames";
import AppSettings from "../data/AppSettings";
import ChampsWithFilters from "./ChampsWithFilters";
import { hasDifferentPicks } from "../utils/stateHelpers";

interface RoomProps {
  version: string;
  appSettings: AppSettings;
}

const Room = (props: RoomProps) => {
  const { version, appSettings } = props;
  const { id, password } = useParams();
  // const [champions, setChampions] = useState(undefined);
  const champions = useLolChampions(version);
  // const [roles, setRoles] = useState(undefined);
  const roles = useLolRoles();
  const [state, setState] = useState<RoomState>();
  const [side, setSide] = useState<"blue" | "red" | "none">("none");
  const [error, setErrorState] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [countdownFinish, setCountdownFinish] = useState(Date.now());
  const [hoverChampion, setHoverChampion] = useState<Champion>();
  // const [champNameFilter, setChampNameFilter] = useState("");
  // const [chosenRole, setChosenRole] = useState("");
  // const input = createRef();

  const [playOnBan] = useSound(
    'https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-champ-select/global/default/sounds/sfx-cs-draft-notif-yourban.ogg',
    { volume: 0.25 }
  );
  const [playOnPick] = useSound(
    'https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-champ-select/global/default/sounds/sfx-cs-draft-notif-yourpick.ogg',
    { volume: 0.25 }
  );

  const onOpen = () => {
    setErrorState("");
    // eslint-disable-next-line no-use-before-define
    sendJsonMessage({ type: "joinroom", roomId: id, password });
  };

  const onMessage = (message: MessageEvent<string>) => {
    const parsedMessage: Message = JSON.parse(message.data);
    if (parsedMessage.type === "statechange") {
      setState((oldState) => {
        if(appSettings.soundsEnabled && hasDifferentPicks(oldState, parsedMessage.newState)){
          if(parsedMessage.newState && side !== "none" && parsedMessage.newState.state === "ongoing" && parsedMessage.newState.nextTeam === side){
            console.log(parsedMessage.newState.nextTeam, side);
            if(parsedMessage.newState.nextType === "ban"){
              playOnBan();
            }else if(parsedMessage.newState.nextType === "pick"){
              playOnPick();
            }
          }
        }
        return parsedMessage.newState;
      });
      if (parsedMessage.newState.nextTimeout >= 0) {
        // setCountdown(parsedMessage.newState.nextTimeout);
        setCountdownFinish(Date.now() + parsedMessage.newState.nextTimeout);
      }
    } else if (parsedMessage.type === "sidechoose") {
      setSide(parsedMessage.side);
    } else if (parsedMessage.type === "error") {
      setErrorState(parsedMessage.reason);
    }
  };

  const { sendJsonMessage } = useWebSocket(getWebsocketUrl, {
    onOpen,
    onMessage,
    onClose: () => console.log("WebSocket Client Disconnected"),
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
  });

  useEffect(() => {
    const intRef = setInterval(() => {
      setCountdown(Math.max(countdownFinish - Date.now(), 0));
    }, 200);
    return () => {
      clearInterval(intRef);
    };
  }, [countdownFinish]);

  if (champions == null || roles == null || state == null) {
    return <></>;
  }

  // const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChampNameFilter(event.target.value);
  // };

  const formatTimer = (timer: number) => Math.trunc(timer);

  const sendReadyToggle = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    sendJsonMessage({ type: "setready", toggle: true });
    event.preventDefault();
  };

  const onHover = (champion: Champion) => {
    sendJsonMessage({ type: "choosechampion", chosenChamp: champion.id });
    setHoverChampion(champion);
  };

  const onLock = () => {
    if (hoverChampion !== undefined)
      sendJsonMessage({
        type: "lockchampion",
        chosenChamp: hoverChampion.id,
      });
  };

  const isSlotDisabled = (arrayName: string, index: number) => {
    const foundElement = optionsToDisableBoth.find(
      (opt) => opt.index === index && opt.array === arrayName
    );
    if (foundElement) {
      return state.disabledTurns.includes(foundElement.turn);
    }
    return false;
  };

  if (error !== undefined && error !== "") {
    console.log("error", error);
    return (
      <div>
        Error: {error}
        <br />
        <Link to="/">Create a new room</Link>
      </div>
    );
  }

  // const roomSideColumn = (side: "blue" | "red") => (
  //   <div className="roomSideColumn">
  //     {roomBanRow(side)}
  //     {roomPickColumn(side)}
  //   </div>
  // );

  const champImageUrl = (champion: Champion) =>
    champion.image
      ? `https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.image.full}`
      : "/square.png";

  const champImageUrlTiled = (champion: Champion) =>
    champion.image
      ? `https://ddragon.leagueoflegends.com/cdn/img/champion/tiles/${fixChampTileNames(
          champion.id
        )}_0.jpg`
      : "/square.png";

  const champImageUrlSplash = (champion: Champion) =>
    champion.image
      ? `https://cdn.communitydragon.org/latest/champion/${champion.key}/splash-art/centered/skin/0`
      : "/rectangle.png";

  const isChampDisabled = (championName: string) =>
    []
      .concat(
        // @ts-ignore
        state.bluePicks || [],
        state.redPicks || [],
        state.blueBans || [],
        state.redBans || [],
        state.disabledChamps || []
      )
      .some((champion) => champion === championName);

  // const toggleRoleFilter = (role: string) => (event: React.MouseEvent) => {
  //   const mRole = role.toUpperCase();
  //   if (chosenRole === mRole) {
  //     setChosenRole("");
  //   } else {
  //     setChosenRole(mRole);
  //   }
  //   event.preventDefault();
  // };

  const champSquare2 = (
    champion: Champion,
    className = "",
    highlight = false,
    disabled = false
  ) => (
    <div
      key={champion.id}
      className={`${className} champSquareSmall`}
      onDragStart={(e) => e.preventDefault()}
    >
      <div className="champImageWrapper">
        <div
        className="champImageSmall"
          data-id={champion.key != null ? champion.key : -1}
          style={{
            backgroundImage: disabled
              ? "url(/disabled.png)"
              : `url(${champImageUrl(champion)})`,
          }}
        />
      </div>
      {highlight ? <div className="champHighlight" /> : <></>}
      {/* {isBan && appSettings.bansPosition === "tournamentRealm" ? <div className="disabledImage" /> : <></>} */}
    </div>
  );

  const champSquareBanTourn = (
    champion: Champion,
    className = "",
    highlight = false,
    disabled = false
  ) => (
    <div
      key={champion.id}
      className={`${className} champSquareSmall`}
      onDragStart={(e) => e.preventDefault()}
    >
      <div className="champImageWrapper">
        <div
        className="banChamp"
          data-id={champion.key != null ? champion.key : -1}
          style={{
            backgroundImage: disabled
              ? "url(/disabled.png)"
              : `url(${champImageUrlSplash(champion)})`,
          }}
        />
      </div>
      {highlight ? <div className="champHighlight" /> : <></>}
      {/* {isBan && appSettings.bansPosition === "tournamentRealm" ? <div className="disabledImage" /> : <></>} */}
    </div>
  );

  const champSquareWide = (
    champion: Champion,
    className = "",
    highlight = false,
    disabled = false
  ) => (
    <div
      key={champion.id}
      className={`${className} champSquareWide`}
      onDragStart={(e) => e.preventDefault()}
    >
      <div className="champImageWrapper">
        <div
          className="champImage"
          data-id={champion.key != null ? champion.key : -1}
          style={{
            backgroundImage: disabled
              ? "url(/disabled.png)"
              : `url(${champImageUrlSplash(champion)})`,
          }}
        />
      </div>
      {highlight ? <div className="champHighlight" /> : <></>}
      <p className="roomChampName roomBigChamp">{champion.name}</p>
    </div>
  );

  const champWithName = (
    champion: Champion,
    className = "",
    highlight = false,
    disabled = false
  ) => (
    <div className="roomChampRow" key={champion.id}>
      {appSettings.sideView === "large"
        ? champSquareWide(champion, className, highlight, disabled)
        : champSquare2(champion, className, highlight, disabled)}
      {appSettings.sideView === "large" ? (
        <></>
      ) : (
        <p className="roomChampName roomSmallChamp">{champion.name}</p>
      )}
    </div>
  );

  const emptyChampArray = [-5, -4, -3, -2, -1];

  const roomTimerTeamname = (teamSide: "blue" | "red") => (
    <div className="roomReady">
      <p className="roomTeamName">
        {
          // @ts-ignore
          `\u00a0${state[`${teamSide}Name`]}\u00a0`
        }
      </p>
      <div
        className={`roomReadyBackground roomReadyBackground${teamSide} ${
          // @ts-ignore
          (state[`${teamSide}Ready`] && state.state === "waiting") ||
          (state.nextTeam === teamSide && state.state === "ongoing")
            ? "active"
            : ""
        }`}
      />
    </div>
  );

  const statusCorrelations = {
    blue: "Blue",
    red: "Red",
    ban: "Ban",
    pick: "Pick",
  };

  const roomTimerBar = () => (
    <div className="roomTimerBar" onClick={sendReadyToggle}>
      {roomTimerTeamname("blue")}
      {state.state === "ongoing" ? (
        <div className="roomTimerContainer">
          <p className="roomTimer">{formatTimer(countdown / 1000)}</p>
          <p className="roomTimerSubtext">
            {statusCorrelations[state.nextTeam]}{" "}
            {statusCorrelations[state.nextType]}
          </p>
        </div>
      ) : (
        <></>
      )}
      {roomTimerTeamname("red")}
    </div>
  );
  // const roomCenterColumn = () => (
  //   <div className="roomCenterColumn">
  //     {roomTimerBar()}
  //     <ChampsWithFilters
  //       version={version}
  //       isChampDisabled={isChampDisabled}
  //       onChampSelected={onHover}
  //     />
  //     {/* {roomFilters()}
  //     {roomChampionList()} */}
  //     {roomLock()}
  //   </div>
  // );

  const roomLock = () => {
    switch (state.state) {
      case "waiting":
        if (side === "blue") {
          return (
            <div
              className={`roomLock ${state.blueReady ? "" : "active"}`}
              onClick={sendReadyToggle}
              onDragStart={(e) => e.preventDefault()}
            >
              {state.blueReady ? "Waiting..." : "Ready"}
            </div>
          );
        }
        if (side === "red") {
          return (
            <div
              className={`roomLock ${state.redReady ? "" : "active"}`}
              onClick={sendReadyToggle}
            >
              {state.redReady ? "Waiting..." : "Ready"}
            </div>
          );
        }
        return <div className={`roomLock `}>Waiting... </div>;
      case "ongoing":
        return (
          <div
            className={`roomLock ${state.nextTeam === side ? "active" : ""}`}
            onClick={onLock}
          >
            {state.nextTeam === side ? "Lock" : "Waiting..."}
          </div>
        );
      default:
        return <div className={`roomLock `}> Finished </div>;
    }
  };

  // const roomChampionList = () => (
  //   <div className="roomChampionsList">
  //     {Object.values(champions)
  //       .filter((champion) =>
  //         new RegExp(champNameFilter.split("").join(".*"), "i").test(
  //           champion.name
  //         )
  //       )
  //       .filter((champion) => {
  //         if (chosenRole in roles) {
  //           // @ts-ignore
  //           return champion.key in roles[chosenRole];
  //         }
  //           return true;

  //       })
  //       .map((champion) => (
  //         <img
  //           key={champion.id}
  //           alt={champion.name}
  //           // @ts-ignore
  //           disabled={isChampDisabled(champion.id)}
  //           onClick={() =>
  //             !isChampDisabled(champion.id) ? onHover(champion) : {}
  //           }
  //           className="roomImgListItem"
  //           onDragStart={(e)=>e.preventDefault()}
  //           src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.image.full} `}
  //         />
  //       ))}
  //   </div>
  // );

  // const positions = ["top", "jungle", "middle", "bottom", "utility"];

  // const roomFilters = () => (
  //   <div className="roomFilters">
  //     <div className="roomFiltersPositions">
  //       {positions.map(positionToImage)}
  //     </div>
  //     {searchBox()}
  //   </div>
  // );

  // const searchBox = () => (
  //   <div className="roomFiltersSearch">
  //     <input onChange={onFilterChange} />
  //     <img src="/search-icon.svg" alt="" />
  //   </div>
  // );

  // const positionToImage = (pos: string) => (
  //   <img
  //     key={pos}
  //     alt={pos}
  //     onClick={toggleRoleFilter(pos)}
  //     className={`roomFiltersPositionsInd ${
  //       pos.toUpperCase() === chosenRole ? "highlight" : ""
  //     }`}
  //     src={`/position-${pos}.svg`}
  //   />
  // );

  // const topRow = () => roomTimerBar();

  // const midRow = () => (
  //   <div className="roomMidRow">
  //     {roomPickColumn("blue")}
  //     {/* <div className="roomChampsWithFilters">
  //       {roomFilters()}
  //       {roomChampionList()}
  //     </div> */}
  //     <ChampsWithFilters
  //       version={version}
  //       isChampDisabled={isChampDisabled}
  //       onChampSelected={onHover}
  //     />
  //     {roomPickColumn("red")}
  //   </div>
  // );

  // const bottomRow = () => (
  //   <div className="roomBottomRow">
  //     {roomBanRow("blue")}
  //     {roomLock()}
  //     {roomBanRow("red")}
  //   </div>
  // );

  const roomBanRow = (banSide: "blue" | "red") => (
    <div className={`roomBanRow ${banSide}`}>
      {
        // @ts-ignore
        (state[`${banSide}Bans`] || [])
          .concat(emptyChampArray)
          .slice(0, 5)
          .map(
            (championName: string) =>
              champions[championName] || {
                id: championName.toString(),
              }
          )
          .map((champion: Champion, index: number) => (
            <div className="banChampContainer">
              <img
                alt={champion.name}
                src="data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'&gt;&lt;/svg&gt;"
              />
              {appSettings.bansPosition === "normal" ? champSquare2(
                champion,
                `banChamp`,
                state.nextTeam === banSide &&
                  // @ts-ignore
                  state[`${banSide}Bans`].length === index + 1 &&
                  state.nextType === "ban",
                isSlotDisabled(`${banSide}Bans`, index)
              ): champSquareBanTourn(
                champion,
                `wrapper`,
                state.nextTeam === banSide &&
                  // @ts-ignore
                  state[`${banSide}Bans`].length === index + 1 &&
                  state.nextType === "ban",
                isSlotDisabled(`${banSide}Bans`, index)
              )}
            </div>
          ))
      }
    </div>
  );

  const roomPickColumn = (pickSide: "blue" | "red") => (
    <div className={`roomPickColumn ${pickSide}`}>
      {
        // @ts-ignore
        (state[`${pickSide}Picks`] || [])
          .concat(emptyChampArray)
          .slice(0, 5)
          .map(
            (championName: string) =>
              champions[championName] || {
                id: championName.toString(),
              }
          )
          .map((champion: Champion, index: number) =>
            champWithName(
              champion,
              `pickChamp`,
              state.nextTeam === pickSide &&
                // @ts-ignore
                state[`${pickSide}Picks`].length === index + 1 &&
                state.nextType === "pick",
              isSlotDisabled(`${pickSide}Picks`, index)
            )
          )
      }
    </div>
  );

  if (!champions || !roles) {
    return <div />;
  }
  // if (appSettings.bansPosition === "tournamentRealm") {
  //   return (
  //     <div className="roomContainer vertical">
  //       {topRow()}
  //       {midRow()}
  //       {bottomRow()}
  //     </div>
  //   );
  // }
  // return (
  //   <div className="roomContainer horizontal">
  //     {roomSideColumn("blue")}
  //     {roomCenterColumn()}
  //     {roomSideColumn("red")}
  //   </div>
  // );

  return (
    <div
      className={`roomContainer ${
        appSettings.bansPosition === "tournamentRealm" ? "bansTournament" : ""
      }`}
    >
      {roomTimerBar()}
      {roomPickColumn("blue")}
      {roomPickColumn("red")}
      {roomBanRow("blue")}
      {roomBanRow("red")}
      <ChampsWithFilters
        version={version}
        isChampDisabled={isChampDisabled}
        onChampSelected={onHover}
      />
      {roomLock()}
    </div>
  );
};

export default Room;
