import { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Roles from "common/types/Roles";

const loadRoles = async (): Promise<Roles> => {
  const res = await (
    await fetch(
      "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-champion-statistics/global/default/rcp-fe-lol-champion-statistics.js"
    )
  ).text();

  const regex = /(?<=JSON\.parse\('){[A-Z,:"]*?.({.*}[A-Z,:"]*?){5}}(?='\))/;
  const matches = res.match(regex);
  if (matches == null || matches.length === 0) {
    return { TOP: {}, JUNGLE: {}, MIDDLE: {}, BOTTOM: {}, UTILITY: {} };
  }
  const parsed = JSON.parse(matches[0]);
  const renamed = { TOP: parsed.TOP, JUNGLE: parsed.JUNGLE, MIDDLE: parsed.MIDDLE, BOTTOM: parsed.BOTTOM, UTILITY: parsed.UTILITY || parsed.SUPPORT };
  return renamed;
};

const useLolRoles = () => {
  const [roles, setRoles] = useState<Roles>();
  useEffect(() => {
    loadRoles().then(setRoles);
  }, []);
  return roles;
};

export default useLolRoles;
