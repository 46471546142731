import { useEffect } from "react";

var rulesFromCssText = function (styleContent: string): CSSRuleList {
    var doc = document.implementation.createHTMLDocument(""),
        styleElement = document.createElement("style");
    
    styleElement.textContent = styleContent;
    // the style will only be parsed once it is added to a document
    doc.body.appendChild(styleElement);
    
    return styleElement.sheet.cssRules;
};

const hasDataIdRegex = /data-id/;
const isBackgroundRegex = /\.background/;
const isBanRegex = /\.ban/;
const getDataIdRegex = /data-id="(\d+)"/;

const champPickSelector = ".champImage";
const champBanSelector = ".banChamp";

const defaultRule1 = `
${champPickSelector} {
    background-size: 241%;
    background-position: 52% 28%;
    background-repeat: no-repeat;
}`;
const defaultRule2 = `
${champBanSelector} {
    background-size: 360%;
    background-position-x: 51%;
    background-position-y: 27%;
    background-repeat: no-repeat;
}`;
const defaultRule3 = `
${champPickSelector}[data-id="-1"] {
    background-size: 100%;
    background-position: 50%;
}`;
const defaultRule4 = `
${champBanSelector}[data-id="-1"] {
    background: no-repeat;
    background-size: cover;
    background-position: 50%;
    filter: grayscale(0) !important;
}`;

const loadChampCss = () => {
  useEffect(() => {
    (async () => {
      const origCss = await (
        await fetch(
          "https://raw.communitydragon.org/latest/plugins/rcp-fe-lol-esports-spectate/global/default/rcp-fe-lol-esports-spectate.css"
        )
      ).text();
      const rules = rulesFromCssText(origCss);
      const sheet = window.document.styleSheets[0];
      sheet.insertRule(defaultRule1, sheet.rules.length);
      sheet.insertRule(defaultRule2, sheet.rules.length);
      sheet.insertRule(defaultRule3, sheet.rules.length);
      sheet.insertRule(defaultRule4, sheet.rules.length);
      for (let i = 0; i < rules.length; i++){
        const rule: CSSRule = rules[i];
        if (!( rule instanceof CSSStyleRule)) {
            continue;
        }
        if(hasDataIdRegex.test(rule.selectorText)){
            sheet.insertRule(toRule(rule), sheet.cssRules.length);
        }
      }
    })();
  }, []);
};

const toRule = (rule: CSSStyleRule): string => {
    const id = getDataIdRegex.exec(rule.selectorText)[1];
    let selector;
    const rules = [];
    if(isBackgroundRegex.test(rule.selectorText)){
        selector = `${champPickSelector}[data-id="${id}"]`;
        if(rule.style.backgroundPositionX){
            rules.push(`background-position-x: ${rule.style.backgroundPositionX}`);
        }
        if(rule.style.backgroundPositionY){
            rules.push(`background-position-y: ${rule.style.backgroundPositionY}`);
        }
        if(rule.style.backgroundSize){
            rules.push(`background-size: ${rule.style.backgroundSize}`);
        }
    }else if(isBanRegex.test(rule.selectorText)){
        selector = `${champBanSelector}[data-id="${id}"]`;
        if(rule.style.backgroundPositionX){
            rules.push(`background-position-x: ${rule.style.backgroundPositionX}`);
        }
        if(rule.style.backgroundPositionY){
            rules.push(`background-position-y: ${rule.style.backgroundPositionY}`);
        }
        if(rule.style.backgroundSize){
            const sizePx = Number(rule.style.backgroundSize.replace(/\w/,""));

            rules.push(`background-size: ${sizePx/10}%`);
        }
    }else{
        selector = `[data-id="${id}"]`;
        if(rule.style.transform){
            rules.push(`transform: ${rule.style.transform}`);
        }
    }
    return `${selector} { ${rules.join(";")} }`;
};

export default loadChampCss;
