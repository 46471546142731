import React, { useEffect } from "react";

import "../App.scss";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CreateRoom from "./CreateRoom";
import Room from "./Room";
import useLolVersion from "../hooks/useLolVersion";
import RoomInfo from "./RoomInfo";
import { useAppSettings } from "../data/AppSettings";
import BurgerMenu from "./BurgerMenu";
import ChampsWithFilters from "./ChampsWithFilters";
import loadChampCss from "../loaders/loadCss";

const App = () => {
  loadChampCss();
  const version = useLolVersion();

  const [appSettings, appSettingsSetters] = useAppSettings();

  useEffect(() => {
    localStorage.setItem("draftlol-app-settings", JSON.stringify(appSettings));
  }, [appSettings]);

  if (!version) {
    return <Router />;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/champs" element={<ChampsWithFilters version={version} onChampSelected={()=>{}} isChampDisabled={()=>false}/>} />
          <Route path="/:roomId/:adminPassword/:bluePassword/:redPassword" element={<RoomInfo />} />
          <Route path="/:id/:password"element={<Room version={version} appSettings={appSettings} />} />
          <Route path="/:id"element={<Room version={version} appSettings={appSettings} />} />
          <Route path="/"element={<CreateRoom version={version} />} />
        </Routes>
      </Router>
      <BurgerMenu appSettings={appSettings} appSettingsSetters={appSettingsSetters} />
    </>
  );
};

export default App;
