export const optionsToDisableBlue = [
  { turn: 0, display: "Disable First ban", index: 0, array: "blueBans" },
  { turn: 2, display: "Disable Second ban", index: 1, array: "blueBans" },
  { turn: 4, display: "Disable Third ban", index: 2, array: "blueBans" },
  { turn: 13, display: "Disable Fourth ban", index: 3, array: "blueBans" },
  { turn: 15, display: "Disable Fifth ban", index: 4, array: "blueBans" },
  { turn: 6, display: "Disable First pick", index: 0, array: "bluePicks" },
  { turn: 9, display: "Disable Second pick", index: 1, array: "bluePicks" },
  { turn: 10, display: "Disable Third pick", index: 2, array: "bluePicks" },
  { turn: 17, display: "Disable Fourth pick", index: 3, array: "bluePicks" },
  { turn: 18, display: "Disable Fifth pick", index: 4, array: "bluePicks" },
];
export const optionsToDisableRed = [
  { turn: 1, display: "Disable First ban", index: 0, array: "redBans" },
  { turn: 3, display: "Disable Second ban", index: 1, array: "redBans" },
  { turn: 5, display: "Disable Third ban", index: 2, array: "redBans" },
  { turn: 12, display: "Disable Fourth ban", index: 3, array: "redBans" },
  { turn: 14, display: "Disable Fifth ban", index: 4, array: "redBans" },
  { turn: 7, display: "Disable First pick", index: 0, array: "redPicks" },
  { turn: 8, display: "Disable Second pick", index: 1, array: "redPicks" },
  { turn: 11, display: "Disable Third pick", index: 2, array: "redPicks" },
  { turn: 16, display: "Disable Fourth pick", index: 3, array: "redPicks" },
  { turn: 19, display: "Disable Fifth pick", index: 4, array: "redPicks" },
];

export const optionsToDisableBoth = optionsToDisableBlue.concat(
  optionsToDisableRed
);
