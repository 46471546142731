/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// eslint-disable-next-line import/no-extraneous-dependencies
import Champion from "common/types/Champion";
import React, { useState } from "react";
import useLolChampions from "../hooks/useLolChampions";
import useLolRoles from "../hooks/useLolRoles";
import CrossIcon from "../icons/Cross";
import SearchIcon from "../icons/Search";

interface ChampsWithFiltersProps {
  version: string;
  isChampDisabled: (id: string) => boolean;
  onChampSelected: (champ: Champion) => void;
  allowSelectDisabled?: boolean;
}

const positions = ["top", "jungle", "middle", "bottom", "utility"];

const ChampsWithFilters = (props: ChampsWithFiltersProps) => {
  const { version, isChampDisabled, onChampSelected, allowSelectDisabled } = props;

  const champions = useLolChampions(version);
  const roles = useLolRoles();

  const [champNameFilter, setChampNameFilter] = useState("");
  const [chosenRole, setChosenRole] = useState("");

  if (!champions || !roles) {
    return <></>;
  }

  const toggleRoleFilter = (role: string) => (event: React.MouseEvent) => {
    const mRole = role.toUpperCase();
    if (chosenRole === mRole) {
      setChosenRole("");
    } else {
      setChosenRole(mRole);
    }
    event.preventDefault();
  };

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setChampNameFilter(event.target.value);

  const onDeleteClick = () =>
    setChampNameFilter("");

  const positionToImage = (pos: string) => (
    <img
      key={pos}
      alt={pos}
      onClick={toggleRoleFilter(pos)}
      className={`champsFiltersPositionsInd ${pos} ${
        pos.toUpperCase() === chosenRole ? "highlight" : ""
      }`}
      src={`/position-${pos}.svg`}
    />
  );

  const searchBox = () => (
    <div className="champsFiltersSearch">
      <input value={champNameFilter} onChange={onFilterChange} />
      <SearchIcon className="search"/>
      {/* <img className="search" alt="" /> */}
      <CrossIcon className="cross" onClick={onDeleteClick}/>
      {/* <img className="cross" alt="" onClick={onDeleteClick}/> */}
    </div>
  );

  const champsFilters = () => (
    <div className="champsFilters">
      <div className="champsFiltersPositions">
        {positions.map(positionToImage)}
      </div>
      {searchBox()}
    </div>
  );

  const championList = () => (
    <div className="roomChampionsList">
      {Object.values(champions)
        .filter((champion) =>
          new RegExp(champNameFilter.split("").join(".*"), "i").test(
            champion.name
          )
        )
        .filter((champion) => {
          if (chosenRole in roles) {
            // @ts-ignore
            return champion.key in roles[chosenRole];
          } 
            return true;
          
        })
        .map((champion) => (
          <img
            key={champion.id}
            alt={champion.name}
            // @ts-ignore
            disabled={isChampDisabled(champion.id)}
            onClick={() =>
              allowSelectDisabled || !isChampDisabled(champion.id) ? onChampSelected(champion) : {}
            }
            className="roomImgListItem"
            onDragStart={(e) => e.preventDefault()}
            src={`https://ddragon.leagueoflegends.com/cdn/${version}/img/champion/${champion.image.full} `}
          />
        ))}
    </div>
  );

  return (
    <div className="champsWithFilters">
      {champsFilters()}
      {championList()}
    </div>
  );
};

export default ChampsWithFilters;
