import { RefObject } from "react";

const copyToClipboard = (ref: RefObject<HTMLInputElement>) => (
  // e: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  if (ref.current) {
    ref.current.select();
  }
  document.execCommand("copy");
  // e.target.focus();
};

export default copyToClipboard;