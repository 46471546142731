import { useEffect, useState } from "react";

const useLolVersion = () => {
  const [version, setVersion] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      setVersion(
        (await (await fetch(
          "https://ddragon.leagueoflegends.com/api/versions.json"
        )).json())[0]
      );
    })();
  }, []);
  return version;
};

export default useLolVersion;