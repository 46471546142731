import React from "react";

const CrossIcon = (props) => (
  <svg
    {...props}
    version="1.1"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path stroke="#5d5f57" d="M3,3 29,29 M3,29 29,3" />
  </svg>
);

export default CrossIcon;
