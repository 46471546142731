/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import AppSettings, { AppSettingsSetters } from "../data/AppSettings";
import SocialIconsBar from "./SocialIconsBar";

interface BurgerMenuProps {
  appSettings: AppSettings;
  appSettingsSetters: AppSettingsSetters;
}

const BurgerMenu = (props: BurgerMenuProps) => {
  const { appSettings, appSettingsSetters } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className={`burgerButton ${open ? "open" : ""}`}
        onClick={() => setOpen(!open)}
      >
        <div />
        <div />
        <div />
      </button>
      <nav className={`burgerMenu ${open ? "open" : ""}`}>
        <p/>
        <div onClick={appSettingsSetters.cycleSideView}>Picks: {appSettings.sideView === "large" ? "large" : "small"}</div>
        <div onClick={appSettingsSetters.cycleBansView}>
          Bans:{" "}
          {appSettings.bansPosition === "tournamentRealm"
            ? "tournament"
            : "normal"}
        </div>
        <div onClick={appSettingsSetters.toggleSoundsEnabled}>Sounds: {appSettings.soundsEnabled === true ? "enabled" : "disabled"}</div>
        <SocialIconsBar singleLine/>
      </nav>
    </>
  );
};

export default BurgerMenu;
